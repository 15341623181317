<template>
    <div class="content-wrapper">
        <div class="content-lookup">
            <div class="lookup-left">
                <ul class="lookup-condition">
                    <li class="field">
                        <div class="title">사업장</div>
                        <ul class="content">
                            <li class="item">
                                <ejs-dropdownlist
                                        v-model="search.bizCode"
                                        :dataSource="businessPlaceDropdownListDatasource"
                                        :allowFiltering="false"
                                        :fields="{ text: 'text', value: 'value' }"
                                        cssClass="lookup-condition-dropdown"/>
                            </li>
                        </ul>
                    </li>
                    <li class="field">
                        <div class="title">사용자</div>
                        <ul class="content">
                            <li class="item">
                                <input-text
                                    v-model="search.userName"
                                    placeholder="내용을 입력하세요." />
                            </li>
                        </ul>
                    </li>
                    <li class="field">
                        <div class="title">사용일자</div>
                        <ul class="content">
                            <li class="item dateRange">
                                <input-date-range
                                    type="lookup-condition"
                                    v-model="search.dateRange"/>
                            </li>
                        </ul>
                    </li>

                    <search-menu-list ref="childComp"/>

                </ul>
                <div class="lookup-lookup">
                   <erp-button
                      button-div="GET"
                      :is-shortcut-button="true"
                      v-on:click.native="fetchProgramUseHistory"
                  >
                    조회
                  </erp-button>
                </div>
            </div>
        </div>

        <div class="content-body">
            <report-view
                ref="reportViewComponent"
                :isPopup="true"/>
            <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                    <div class="section-header">
                        <div class="header-left">
                            <div class="header-title">프로그램 사용이력</div>
                            <div class="header-caption">[{{ count }}건]</div>
                            <div class="header-caption">임시 IP 표출 : {{ipAddress}}</div>
                        </div>
                        <div class="header-right">
                            <ul class="header-button">
                              <li class="print">
                                <erp-button
                                    button-div="PRINT"
                                    @click.native="onPrintClick"
                                    :is-shortcut-button="true"
                                >인쇄
                                </erp-button>

                              </li>
                              <li class="print">
                                <erp-button
                                    button-div="FILE"
                                    @click.native="onExcelClick"
                                >
                                  Excel
                                </erp-button>
                              </li>
                            </ul>
                        </div>
                    </div>
                    <div class="section-body">
                        <div class="body-grid">
                            <ejs-grid-wrapper
                                ref="grid"
                                :allowEditing="false"
                                :allowExcelExport="true"
                                :allowPaging="true"
                                :allowResizing="true"
                                :isSelectedRowRetain="true"
                                :provides="grid"
                                :pageSettings="pageSettings"
                                :columns="columns"
                                :data-source="content.programUseHist"
                                @actionComplete="programUseHistoryGridActionComplete"/>
                        </div>
                    </div>
                </section>
            </article>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import ReportView from "../../components/common/report/ReportView.vue";
import ejsGridWrapper from "../../components/common/EjsGridWrapper.vue";
import InputText from "../../components/common/text/InputText.vue";
import InputDateRange from "../../components/common/datetime/InputDateRange";
import SearchMenuList from "./common/SearchMenuList.vue";
import commonMixin from "../../views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { getBizCodesObject } from "../../utils/pageKeyUtil";
import GolfErpAPI from "../../api/v2/GolfErpAPI";
import {ExcelExport, ForeignKey, Page, Resize} from "@syncfusion/ej2-vue-grids";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import {numberWithCommas} from "@/utils/number";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
    name: "ProgramUseHist",
    mixins: [commonMixin, confirmDialogMixin],
    props: {},
    components: {
        EjsGridWrapper,
        ejsGridWrapper,
        ReportView,
        InputDateRange,
        InputText,
        SearchMenuList,
        ErpButton,
    },
    created() {
    },
    mounted() {
        this.loadScript();
        document.addEventListener('click', this.childEvent);
    },
    beforeDestroy() {
      document.removeEventListener('click', this.childEvent);
    },
    computed: {
        businessPlaceDropdownListDatasource() {
            const businessPlaceDropdownListDatasource = (
                getBizCodesObject() || []
            ).map(({ bizName, bizCode }) => ({
                text: bizName,
                value: bizCode,
            }));

            businessPlaceDropdownListDatasource.unshift({ text: "ALL", value: "" });
            return businessPlaceDropdownListDatasource;
        }
    },
    data() {
        return {
            count:0,
            ipAddress: '',
            search: {
                bizCode: '',
                userName: '',
                menuName: '',
                dateRange: {
                    from: moment().startOf('month').format('YYYY-MM-DD'),
                    to: moment().format('YYYY-MM-DD'),
                },
            },
            content: {
                programUseHist: []
            },
            grid: [
                Resize,
                Page,
                ExcelExport,
                ForeignKey,
            ],
            columns: [
                {
                    field: 'logId',
                    visible: false,
                    type: 'number',
                },
                {
                    field: 'businessPlace.name',
                    headerText: '사업장',
                    textAlign: 'center',
                    width: 100,
                    type: 'string',
                },
                {
                    field: 'user.name',
                    headerText: '사용자명',
                    textAlign: 'center',
                    width: 50,
                    type: 'string',
                },
                {
                    field: 'insertDt',
                    headerText: '사용일시',
                    textAlign: 'center',
                    width: 100,
                    type: 'string',
                },
                {
                    field: 'menuName',
                    headerText: '프로그램 명',
                    textAlign: 'center',
                    width: 100,
                    type: 'string',
                },
                {
                    field: 'description',
                    headerText: '프로그램 메뉴',
                    width: 100,
                    type: 'string',
                },
                {
                    field: 'pgId',
                    headerText: '프로그램 ID',
                    width: 100,
                    textAlign: 'center',
                    type: 'string',
                },
                {
                    field: 'connectIp',
                    headerText: '접속 IP',
                    width: 100,
                    textAlign: 'center',
                    type: 'string',
                },
            ],
            pageSettings: {pageSize: 50},
        };
    },
    methods: {
        async fetchProgramUseHistory() {
            let params = {
                bizCode: this.search.bizCode,
                userName: this.search.userName,
                fromDate: this.search.dateRange.from,
                toDate: this.search.dateRange.to,
                menuName: this.$refs.childComp.menuName
            };

            let res = await GolfErpAPI.getProgramUseHist(params);
            if (res) {
                this.content.programUseHist = res;
            }
        },
        onPrintClick() {
            if (this.content.programUseHist.length === 0) {
                this.errorToast("조회된 데이터가 없습니다");
            } else {
                const result = this.$refs.grid.getGridBatchData();

                if (result.length < 1) {
                    return this.errorToast(this.$t('report.popupMessage.noData'));
                }

                const searchOptionsList = [
                    {
                        key: '조회일자',
                        value: `${this.search.dateRange.from} ~ ${this.search.dateRange.to}`
                    }
                ];

                const searchOptions = searchOptionsList
                    .filter(item => !!item.value)
                    .map(item => item.key + ': ' + item.value)
                    .join(', ');
                
                const reportData = {
                    reportJson: {
                        jsonData: result,
                        username: this.search.userName,
                        uniqueCode: this.$options.name,
                        searchOptions,
                    },
                };
                this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
            }
        },
        onExcelClick() {
            if (this.content.programUseHist.length === 0) {
                this.errorToast("조회된 데이터가 없습니다");
            } else {
                this.$refs.grid.excelExport();
            }
        },
        programUseHistoryGridActionComplete() {
            this.count = numberWithCommas(
                this.$refs.grid?.getGridBatchCount() || 0
            );
        },
        async loadScript() {
            const response = await axios.get('https://api64.ipify.org?format=json');
            this.getIP(response);
        },
        getIP(res) {
            this.ipAddress = res.data.ip;
        },
        childEvent() {
            if (this.$refs.childComp.menuSearchList.length > 0) {
                this.$refs.childComp.refresh();
            }
        }
    }
};
</script>

<style scoped>

</style>